<template>
  <div>
    <Navbar mode="solid" class="fixed top-0 left-0"/>
    <main class="page-content">
      <div class="page-container">
        <h1 class="page-title">{{ $t('resetpass_page.title') }}</h1>
        <form @submit.prevent="resetUserPassword" class="form" action="">
          <InputText
            :placeholder="$t('resetpass_page.new.placeholder')"
            :label="$t('resetpass_page.new.label')"
            name="password"
            :disabled="loading"
            type="password"
            v-model="form.password"
            :errorMsg="passwordErrMsg"
            :showErrMsg="$v.form.password.$error"
            @blur="$v.form.password.$touch()"
            class="login-input"
          />

          <InputText
            :label="$t('resetpass_page.confirm.label')"
            name="confirm_password"
            :placeholder="$t('resetpass_page.confirm.placeholder')"
            :disabled="loading"
            type="password"
            v-model="form.password_confirmation"
            :errorMsg="confirmPasswordErrMsg"
            :showErrMsg="$v.form.password_confirmation.$error"
            @blur="$v.form.password_confirmation.$touch()"
            class="login-input"
          />

          <ButtonPrimary
            type="submit"
            text="Submit"
            class="w-full"
            :loading="loading"
          />
        </form>
      </div>
    </main>
    <Footer class="footer" />
    <BottomBar class="layout-bar"/>
  </div>
</template>

<script>
const { required, sameAs, minLength } = require('vuelidate/lib/validators');
import { mapActions } from 'vuex';
import BottomBar from '@/components/layout/BottomBar';
import InputText from '@/components/inputs/InputText';
import ButtonPrimary from '@/components/buttons/ButtonPrimary';

export default {
  name: 'ResetPassword',
  components: {
    BottomBar,
    InputText,
    ButtonPrimary
  },
  data() {
    return {
      form: {
        password: '',
        password_confirmation: '',
      },
      loading: false,
    };
  },
  validations: {
    form: {
      password: {
        required,
        minLength: minLength(8),
      },
      password_confirmation: {
        required,
        sameAs: sameAs('password'),
      },
    },
  },
  computed: {
    passwordErrMsg() {
      let msg;
      if (!this.$v.form.password.required) {
        msg = this.$t('resetpass_page.new.error_1'); //Password is required
      }
      if (!this.$v.form.password.minLength) {
        let count = this.$v.form.password.$params.minLength.min;
        msg = this.$tc('resetpass_page.new.error_2', count); //Password must have at least {count} characters
      }
      return msg;
    },
    confirmPasswordErrMsg() {
      let msg;
      if (!this.$v.form.password_confirmation.required) {
        msg = this.$t('resetpass_page.confirm.error_1'); //Password is required
      }
      if (!this.$v.form.password_confirmation.sameAs) {
        msg = this.$t('resetpass_page.confirm.error_2'); //Passwords must match";
      }
      return msg;
    },
  },
  methods: {
    ...mapActions('auth', ['resetPassword']),
    async resetUserPassword() {
      try {
        this.loading = true;
        this.$v.form.$touch();
        if (this.$v.form.$invalid) {
          return false;
        }

        const data = {
          email: this.$route.query.email,
          password: this.form.password,
          password_confirmation: this.form.password_confirmation,
          token: this.$route.query.token,
        };

        const res = await this.resetPassword(data);
        if (!res) return false;
        this.loading = false;

        this.$notify({
          type: 'success',
          text: this.$t('resetpass_page.success'), //You can now login again with your new password
        });

        this.$router.push({
          name: 'Home',
        });
      } catch (error) {
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.page-content {
  @apply px-4 py-12 md:px-12 mt-20;
}

.page-container {
  @apply w-full max-w-md;
  @apply relative transform -translate-x-1/2 left-1/2;
}
.page-title {
  @apply font-sans text-2xl font-semibold text-black-base;
  @apply mb-8;
}

.main {
  @apply pt-36 max-w-screen-sm relative left-1/2 transform -translate-x-1/2 px-4;
}

.footer {
  @apply mt-64;
}

.login-input {
  @apply mb-4;
}

.layout-bar {
  @apply fixed bottom-0 w-full sm:hidden;
}
</style>
